import { createApp } from 'vue'
import App from '@/App.vue'
import '@core-scss/template/index.scss'
import '@styles/styles.scss'
import vuetify from '@/plugins/vuetify'
import layoutsPlugin from '@/plugins/layouts'
import router from '@/plugins/1.router'
import { createPinia } from 'pinia'
import ability from '@/plugins/casl'
import i18n from './plugins/i18n'
import iconify from './plugins/iconify'
import { VueMaskDirective } from 'v-mask'
import VueHtmlToPaper from 'vue-html-to-paper'

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: false, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

const vMaskV2 = VueMaskDirective

const vMaskV3 = {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind,
}

// Create vue app
const app = createApp(App).directive('mask', vMaskV3)

ability(app)

// Use plugins
vuetify(app)
app.use(createPinia())

app.use(VueHtmlToPaper,options)
app.use(router)
app.use(layoutsPlugin)



i18n(app)

// Register plugins
//registerPlugins(app)

// Mount vue app
app.mount('#app')
