<script setup>
import MyPosts from '@/views/post-list/MyPosts.vue'
import { router } from '@/plugins/1.router'
import { dataStore } from "@/store"



const data = dataStore()
const tabIndex=ref(0)

definePage({
  meta: {
    action: 'manage',
    subject: 'all',
  },
})

const onCreatePost=()=>{
  data.post=null
  router.push({ name: 'create-post' })
}
</script>


<template>
  <div>
    <VCard>
      <div class="d-flex gap-2 ma-2">
        <VBtn
          prepend-icon="tabler-plus"
          @click="onCreatePost"
        >
          Ceate Post
        </VBtn>
        <VBtn
          prepend-icon="tabler-eye"
          :to="{name:'tracker'}"
        >
          Track
        </VBtn>
      </div>
      <VTabs v-model="tabIndex">
        <VTab>
          My Posts
        </VTab>
        <VTab>
          Marketplace Posts
        </VTab>
      </VTabs>
      <MyPosts v-if="tabIndex==0" />
    </VCard>
  </div>
</template>
